<template>
    <Header/>
    <Campusheader/>
    <Campusservice/>
    <Footer/>
</template>

<script>
// @ is an alias to /src

import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
import Campusservice from '@/components/Campusservice.vue'
import Campusheader from '@/components/Campusheader.vue'

export default {
  components: {
     Header,
    Footer,
    Campusservice,
    Campusheader
  }
}
</script>
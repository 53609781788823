<template>
    <section id="services" class="services">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>What we do</h2>
          <p style="padding-top:20px">We do Campus recruitment on your behalf. It is done by a series of steps wherein the initial assessment to filter the talented will be selected and presented. Online assessment are more cost-effective and time-saving, graduates with the perfectly matched skill set would then undergo an interview process where the best are selected.</p>
        </div>

        <div class="row" style="padding-top:30px">
          <div class="col-md-4 d-flex align-items-stretch" v-for="(obj, key) in mobileappJSON" :key="key">
            <div class="icon-box">
            <Image :fpath=" obj.path " class="serviceimage"/>
              <h4 style="text-align:center;">{{ obj.header }}</h4>
            </div>
          </div>
        </div>

      </div>
    </section>
</template>
<script>
import Image from '@/components/Image.vue'
export default {
    components: {
    Image
  },
  data: () =>({
    mobileappJSON: [
      {
        path: 'services/Campus-recruitment.jpg',
        header: 'Campus recruitment',
           },
      {
        path: 'services/Preliminary-Assessment.jpg',
        header: 'Preliminary Assessment',
        },
      {
        path: 'services/HR-Interview.jpg',
        header: 'HR Interview and selection',
        }
    ]

  })
    
}
</script>
<style>
img.serviceimage {
    width: 100%;
    padding-bottom: 30px;
}
.services .icon-box {
  margin-bottom: 20px;
  padding: 20px 20px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
section#services {
    padding: 50px 0px 10px 0px;
}

.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

.services .icon-box h4 {
  color: #05579e;
  transition: 0.3s;
}

.services .icon-box h4 a:hover {
  color: #0880e8;
}


</style>